export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: true,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateValidateOnSubmit: true,

    companyDataPrefillEnabled: false,
    companyItInvoicingEnabled: false,

    competitionNoticeEnabled: false,
    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: false,

    favoriteFilterCategoriesIds: [
      1250,
      1252,
      1251,
      1253,
      1256,
      1254,
      1258,
      1255,
      1257,
      1259,
      1671,
      1260,
      4197,
    ],

    favoritePharmaciesEnabled: false,
    favoriteProductsEnabled: true,

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/loyalty-card/general-loyalty-card.jpg',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: false,

    cardCodeToOtpModeEnabled: false,
    fiscalCodeEnabled: true,
    loyaltyCardNumberDigits: 9,
    loyaltyCardNumberStaticPrefix: '++',
    loyaltyProgramBenefitsCount: 4,
    myAccountSorpEnabled: true,
    standAloneCardModeEnabled: true,

    phoneNumberRequired: false,
    priceEnabled: true,
    priceSummaryEnabled: true,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    returnAndComplaintTitleSplitEnabled: false,
    returnIbanFieldEnabled: false,

    sexEnabled: true,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: false,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: true,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: false,

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: false,
      breastFeeding: false,
      allergies: false,
      treatments: false,
      height: false,
      weight: false,
    },
  },
}
