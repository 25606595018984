export default {
  allow: [
    ...process.env.ROBOTS_ALLOW?.split('|') || [],
    '*&menu=*',
    '*&rating=*',
    '*?amp*',
    '*?amp%3Brefresh*',
    '*?cjdata*',
    '*?fbclid*',
    '*?fresh*',
    '*?gbraid*',
    '*?gclid*',
    '*?m2=*',
    '*?menu=*',
    '*?network*',
    '*?page*',
    '*?p=*',
    '*?rating=*',
    '*?refresh*',
    '*?refreshing*',
    '*?ruleid*',
    '*?sku=*',
    '*?utm*',
    '*?y_source*',
    '/_i/*',
    '/i18n/*'
  ],
  disallow: [
    ...process.env.ROBOTS_DISALLOW?.split('|') || [],
    '/*?*',
    '*/_e*',
    '*/cdn-cgi/l/email-protection',
    '*/graphql*',
    '*krotkie-daty',
    '*pdf',
    '/checkout/*',
    '/moje-konto/edycja-danych$',
    '/moje-konto/moje-zamowienia$',
    '/moje-konto/ulubione-produkty$',
    '/moje-konto$',
    '/my-account/powiadomienia-o-produktach$',
    '/search*',
    '/ulubione-produkty$',
  ],
}
