export default {
  footer: {
    certificates: [
      {
        image: {
          url: '/images/footer/sukl-vet-pl.png',
          alt: 'Główny Inspektorat Weterynarii',
          width: 120,
          height: 100,
        },
        link: 'https://pasze.wetgiw.gov.pl/otc/demo/index.php?jezyk=1',
      },
    ],
    links: [
      {
        url: '/kim-jestesmy',
        text: 'O nas',
      },
      {
        url: '/faq',
        text: 'Pomoc',
      },
      {
        url: '/bezpieczne-zakupy',
        text: 'Bezpieczeństwo',
      },
    ],
  },
}
